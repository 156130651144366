
/**
 * Module dependencies.
 */

import { color, media, units } from 'src/styles/utils';
import { theme } from 'styled-tools';
import React, { ReactElement } from 'react';
import Svg from 'src/components/core/svg';
import Type from 'src/components/core/typography';
import downloadIcon from 'src/assets/svg/download.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  icon?: string,
  title: string,
  url: string
};

/**
 * `Link` styled component.
 */

const Link = styled.a`
  align-items: center;
  border-bottom: 1px solid ${color.transparentize('black', 0.05)};
  color: ${color('textColor')};
  display: grid;
  grid-gap: ${units(2)};
  grid-template-areas: 'downloadTitle downloadIcon';
  grid-template-columns: 1fr max-content;
  outline: none;
  padding: ${units(2.5)} ${units(1)} ${units(2.5)} ${units(2)};
  text-decoration: none;
  transition: background-color ${theme('animations.defaultTransition')};

  ${media.min('ms')`
    padding-right: ${units(3)};
  `};

  &:focus,
  &:hover {
    background-color: ${color.transparentize('black', 0.05)};
  }
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H6).attrs({ as: 'p', small: true })`
  grid-area: downloadTitle;
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg)`
  grid-area: downloadIcon;
  height: ${units(4)};
`;

/**
 * `DownloadItem` component.
 */

const DownloadItem = ({ className, icon, title, url }: Props): ReactElement => (
  <Link
    className={className}
    href={url}
    rel={'noopener'}
    target={'_blank'}
  >
    <Title>
      {title}
    </Title>

    <Icon
      aria-label={'Download'}
      icon={icon ?? downloadIcon}
      size={units(4)}
    />
  </Link>
);

/**
 * Export `DownloadItem` component.
 */

export default DownloadItem;
