/**
 * Module dependencies.
 */

import {
  AmbientsModule,
  AwardsModule,
  BannerModule,
  CatalogsSliderModule,
  CollectionsSliderModule,
  ContactsFormModule,
  ContactsModule,
  DownloadsModule,
  HighlightOneImagesModule,
  HighlightThreeImagesModule,
  HighlightTwoImagesModule,
  IframeModule,
  ImageSliderModule,
  MagazinesSliderModule,
  ShowroomsModule,
  TextImageModule,
  TextImageSubtitleModule,
  TextModule,
  ToolsModule
} from 'src/components/modules';

import { getKeyFromObject } from 'src/core/utils/objects';
import { media, units } from 'src/styles/utils';
import React, { Fragment, ReactElement } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import styled from 'styled-components';

/**
 * `StyledBannerModule` styled component.
 */

const StyledBannerModule = styled(BannerModule)`
  ${media.min('ms')`
    margin-bottom: ${units(8)};
  `}
`;

/**
 * `StyledContactsModule` styled component.
 */

const StyledContactsModule = styled(ContactsModule)`
  ${media.max('ms')`
    padding-top: 0;
  `}
`;

/**
 * `StyledContactsFormModule` styled component.
 */

const StyledContactsFormModule = styled(ContactsFormModule)`
  ${media.max('ms')`
    padding-top: 0;
  `}
`;

/**
 * `StyledCatalogsSliderModule` styled component.
 */

const StyledCatalogsSliderModule = styled(CatalogsSliderModule)`
  padding-top: 0 !important;
`;

/**
 * `StyledCollectionsSliderModule` styled component.
 */

const StyledCollectionsSliderModule = styled(CollectionsSliderModule)`
  padding-top: 0 !important;
`;

/**
 * `StyledHighlightTwoImagesModule` styled component.
 */

const StyledHighlightTwoImagesModule = styled(HighlightTwoImagesModule)`
  padding: 0 0 ${units(13)};

  ${media.min('md')`
    padding: 0 0 ${units(25)};
  `};
`;

/**
 * `StyledHighlightThreeImagesModule` styled component.
 */

const StyledHighlightThreeImagesModule = styled(HighlightThreeImagesModule)`
  padding-top: 0 !important;
`;

/**
 * `StyledImageSliderModule` styled component.
 */

const StyledImageSliderModule = styled(ImageSliderModule)`
  padding-top: 0 !important;
`;

/**
 * `StyledShowroomsModule` styled component.
 */

const StyledShowroomsModule = styled(ShowroomsModule)`
  padding-top: 0 !important;
`;

/**
 * `StyledTextModule` styled component.
 */

const StyledTextModule = styled(TextModule)`
  padding-top: 0 !important;
`;

/**
 * `StyledToolsModule` styled component.
 */

const StyledToolsModule = styled(ToolsModule)`
  padding-top: 0 !important;
`;

/**
 * `StyledMagazinesSliderModule` styled component.
 */

const StyledMagazinesSliderModule = styled(MagazinesSliderModule)`
  padding-top: 0 !important;
`;

/**
 * Export `EmptyModule` styled component.
 */

export const EmptyModule = styled.section`
  height: ${units(13)};
  position: relative;
  width: 100%;

  ${media.min('ms')`
    height: ${units(25)};
  `}
`;

/**
 * Export `modulesName`.
 */

export const modulesName = {
  ambients: 'ambients',
  awards: 'awards',
  banner: 'banner',
  catalogsSlider: 'catalogs slider',
  collectionsSlider: 'collections slider',
  contacts: 'contacts',
  contactsForm: 'contacts form',
  downloads: 'downloads',
  highlightOneImages: 'highlight one image',
  highlightThreeImages: 'highlight three images',
  highlightTwoImages: 'highlight two images',
  iframe: 'iframe',
  imageSlider: 'image slider',
  magazinesSlider: 'magazines slider',
  showrooms: 'showrooms',
  text: 'text',
  textAndImage: 'text and image',
  textAndImageWithSubtitle: 'text and image with subtitle',
  tools: 'tools'
} as const;

/**
 * Modules components.
 */

export const modulesComponents = {
  [modulesName.ambients]: AmbientsModule,
  [modulesName.awards]: AwardsModule,
  [modulesName.banner]: StyledBannerModule,
  [modulesName.catalogsSlider]: StyledCatalogsSliderModule,
  [modulesName.collectionsSlider]: StyledCollectionsSliderModule,
  [modulesName.contacts]: StyledContactsModule,
  [modulesName.contactsForm]: StyledContactsFormModule,
  [modulesName.downloads]: DownloadsModule,
  [modulesName.highlightOneImages]: HighlightOneImagesModule,
  [modulesName.highlightThreeImages]: StyledHighlightThreeImagesModule,
  [modulesName.highlightTwoImages]: StyledHighlightTwoImagesModule,
  [modulesName.iframe]: IframeModule,
  [modulesName.imageSlider]: StyledImageSliderModule,
  [modulesName.magazinesSlider]: StyledMagazinesSliderModule,
  [modulesName.showrooms]: StyledShowroomsModule,
  [modulesName.text]: StyledTextModule,
  [modulesName.textAndImage]: TextImageModule,
  [modulesName.textAndImageWithSubtitle]: TextImageSubtitleModule,
  [modulesName.tools]: StyledToolsModule
};

/**
 * Whitelist spaces.
 */

const whitelistSpaces = [
  modulesName.awards,
  modulesName.downloads,
  modulesName.textAndImage
];

/**
 * Blacklist spaces.
 */

const blacklistSpaces = [
  modulesName.highlightTwoImages,
  modulesName.highlightThreeImages,
  modulesName.imageSlider,
  modulesName.showrooms,
  modulesName.text,
  modulesName.tools
];

/**
 * Export `ModuleName` type.
 */

type ModuleNameConfig = typeof modulesName;
type ModuleNameKey = keyof typeof modulesName;
export type ModuleName = ModuleNameConfig[keyof ModuleNameConfig];

/**
 * Export `Module` type.
 */

export type Module = { [key: string]: any } & {
  id: string;
  module: ModuleName;
  name: string;
};

/**
 * `Props` type.
 */

type Props = {
  allowed?: ModuleNameKey[];
  modules: Module[];
};

/**
 * Normalize modules.
 */

function normalizeModules({ allowed, modules }: Props) {
  if (isEmpty(modules)) {
    return [];
  }

  const validModules = filter(compact(modules), ({ module }) => {
    return Object.values(modulesName).includes(module);
  });

  if (isEmpty(allowed)) {
    return validModules;
  }

  return filter(validModules, ({ module }) => {
    return allowed.includes(getKeyFromObject(modulesName, module));
  });
}
/**
 * `ModulesBuilder` component.
 */

const ModulesBuilder = (props: Props): ReactElement | null => {
  const modules = normalizeModules(props);

  if (isEmpty(modules)) {
    return null;
  }

  return map(modules, ({ module, ...props }, index: number) => {
    const prevModule = modules[index - 1]?.module;
    const currentModule = module;
    const hasEmptySpace = includes(blacklistSpaces, currentModule) && includes(whitelistSpaces, prevModule);
    const Component = modulesComponents[module];

    return (
      <Fragment key={index}>
        {hasEmptySpace && <EmptyModule />}

        <Component
          {...{
            ...props,
            ...index === 0 && { hasPageTitle: true }
          }}
        />
      </Fragment>
    );
  });
};

/**
 * Default props.
 */

ModulesBuilder.defaultProps = {
  allowed: []
};

/**
 * Export `ModulesBuilder` component.
 */

export default ModulesBuilder;
