
/**
 * Module dependencies.
 */

import { RouteName } from 'i18n-routes';
import { absoluteUrlResolver } from './url-resolver';
import { routeTemplate } from 'src/core/utils/routes';
import { routes } from 'src/core/routes';

/**
 * Constants.
 */

const defaultLocale: string = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

/**
 * `Data` type.
 */

type Data = Record<string, any>[];

/**
 * Export `getHrefLangs`.
 */

export function getHrefLangs(data: Data, routeName: RouteName) {
  return data?.map(({ locale, slug }) => {
    const lang = locale?.split('_')[0];    
    const route = routeTemplate(routes?.[lang]?.[routeName], { slug });
    const isDefaultLocale = defaultLocale === lang;
    const defaultUrl = absoluteUrlResolver(`/${lang}`);
  
    return {
      lang,
      url: route ? absoluteUrlResolver(isDefaultLocale ? route : `/${lang}${route}`) : defaultUrl
    };
  });
}
