
/**
 * Module dependencies.
 */

import { color, media, units } from 'src/styles/utils';
import { useTranslate } from 'src/core/utils/translator';
import Container from 'src/components/core/layout/container';
import DownloadItem from 'src/components/downloads/download-item';
import FadeInUpAnimation from 'src/components/core/animations/fade-in-up';
import Parallax from 'src/components/core/animations/parallax';
import React, { ReactElement, forwardRef } from 'react';
import Richtext from 'src/components/core/richtext';
import Type from 'src/components/core/typography';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * Export `Item` type.
 */

export type Item = {
  downloadUrl: string | null,
  name?: string | null,
  type?: 'bim' | 'catalog' | 'epd' | 'images' | 'general' | 'performanceStatement' | 'technicalInformation' | 'technicalManual' | 'upec'
};

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  description?: string | null,
  items: Item[] | null,
  title: string
};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  background-color: ${color('grey200')};
  padding: ${units(13)} 0;
  position: relative;

  ${media.min('ms')`
    padding: ${units(25)} 0;
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  ${media.min('ms')`
    display: grid;
    grid-template-areas:
      '. title       title . list .'
      '. description .     . list .';
    grid-template-columns: 1fr 3fr repeat(2, 1fr) 5fr 1fr;
    grid-template-rows: max-content 1fr;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H2)`
  margin-bottom: ${units(6)};

  ${media.min('ms')`
    grid-area: title;
    grid-row: 1;
  `}
`;

/**
 * `DescriptionWrapper` styled component.
 */

const DescriptionWrapper = styled.div`
  ${media.min('ms')`
    grid-area: description;
    grid-row: 2;
  `}
`;

/**
* `Description` styled component.
*/

const Description = styled(Richtext)`
  margin-bottom: ${units(6)};
`;

/**
 * `List` styled-component.
 */

const List = styled(Parallax)`
  border-top: 1px solid ${color('grey500')};

  ${media.min('ms')`
    grid-area: list;
    grid-row: 1 / -1;
  `}
`;

/**
 * `DownloadsSection` component.
 */

const DownloadsSection = (props: Props, ref?: any): ReactElement | null => {
  const { className, description, items, title } = props;
  const { translate } = useTranslate();

  if (isEmpty(items)) {
    return null;
  }

  return (
    <Section
      className={className}
      ref={ref}
    >
      <Container>
        <Grid>
          <Title>
            <FadeInUpAnimation>
              {title}
            </FadeInUpAnimation>
          </Title>

          {description && (
            <DescriptionWrapper>
              <FadeInUpAnimation>
                <Description>
                  {description}
                </Description>
              </FadeInUpAnimation>
            </DescriptionWrapper>
          )}

          <List speed={-1}>
            {items.map(({ downloadUrl, name, type }: Item, index: number) => (
              <FadeInUpAnimation key={index}>
                <DownloadItem
                  title={name ?? translate(`common:sections.technicalInformation.labels.${type}`)}
                  url={downloadUrl}
                />
              </FadeInUpAnimation>
            ))}
          </List>
        </Grid>
      </Container>
    </Section>
  );
};

/**
 * Export `DownloadsSection` component.
 */

export default forwardRef(DownloadsSection);
