
/**
 * Module dependencies.
 */

import { NavbarProps } from 'src/types/navbar';
import { darkTheme, lightTheme } from './provider';
import { useEffect, useMemo } from 'react';
import { useNavbarThemeContext } from './context';
import isEqual from 'lodash/isEqual';

/**
 * `Props` type.
 */

type Props = {
  theme: NavbarProps | 'dark' | 'light'
};

/**
 * `useNavbarTheme` hook.
 */

function useNavbarTheme({ theme }: Props) {
  const { setTheme, theme: navbarTheme } = useNavbarThemeContext();
  const isThemeDefined = useMemo<boolean>(() => {
    if (theme === 'dark') {
      return isEqual(navbarTheme, darkTheme);
    }

    if (theme === 'light') {
      return isEqual(navbarTheme, lightTheme);
    }

    return isEqual(navbarTheme, theme);
  }, [navbarTheme, theme]);

  useEffect(() => {
    if (isThemeDefined) {
      return;
    }

    if (theme === 'dark') {
      setTheme(darkTheme);

      return;
    }

    if (theme === 'light') {
      setTheme(lightTheme);

      return;
    }

    setTheme(theme);
  }, [isThemeDefined, setTheme, theme]);
}

/**
 * Export `useNavbarTheme` hook.
 */

export default useNavbarTheme;
