
/**
 * Module dependencies.
 */

import AmbientsSection from 'src/components/sections/ambients-section';
import AwardsSection from 'src/components/sections/awards-section';
import BannerSection from 'src/components/sections/banner-section';
import ContactFormSection from 'src/components/sections/contact-form-section';
import ContactsSection from 'src/components/sections/contacts-section';
import DownloadsSection from 'src/components/sections/downloads-section';
import HighlightOneImageSection from 'src/components/sections/highlight-one-image-section';
import HighlightThreeImagesSection from 'src/components/sections/highlight-three-images-section';
import HighlightTwoImagesSection from 'src/components/sections/highlight-two-images-section';
import IframeSection from 'src/components/sections/iframe-section';
import ImagesGallery from 'src/components/sections/images-gallery';
import React from 'react';
import ShowroomsSection from 'src/components/sections/showrooms-section';
import SliderSection from 'src/components/sections/slider-section';
import TextImageSection from 'src/components/sections/text-image-section';
import TextImageSubtitleSection from 'src/components/sections/text-image-subtitle-section';
import TextSection from 'src/components/sections/text-section';
import ToolsSection from 'src/components/sections/tools-section';
import map from 'lodash/map';
import pick from 'lodash/pick';

/**
 * `Props` type.
 */

type Props = { [key: string]: any } & {
  className?: string
};

/*
 * Export `AmbientsModule` component.
 */

export const AmbientsModule = (props: Props) => {
  const { ambientCategories, className, moduleColor, subtitle } = props;

  return (
    <AmbientsSection
      ambients={map(ambientCategories, ambient => ({
        ...pick(ambient, ['description', 'id', 'name']),
        buttonLabel: ambient?.linkLabel,
        buttonUrl: ambient?.linkUrl,
        images: map(ambient?.media, ({ fileUrl }) => fileUrl)
      }))}
      bgColor={moduleColor}
      className={className}
      label={subtitle}
    />
  );
};

/**
 * Export `AwardsModule` component.
 */

export const AwardsModule = (props: Props) => (
  <AwardsSection
    bgColor={props?.moduleColor}
    className={props?.className}
    hasPageTitle={props?.hasPageTitle}
    imageUrl={props?.media[0].fileUrl}
    lead={props?.lead}
    title={props?.title}
  />
);

/**
 * Export `BannerModule` component.
 */

export const BannerModule = ({ banners, ...props }: Props) => (
  <BannerSection
    banners={map(banners, ({ image, linkUrl, subtitle, title, videoUrl }) => ({
      fileUrl: image?.fileUrl,
      id: image?.id,
      linkUrl,
      subtitle,
      title,
      videoUrl
    }))}
    {...props}
  />
);

/**
 * Export `CatalogsSliderModule` component.
 */

export const CatalogsSliderModule = (props: Props) => (
  <SliderSection
    className={props?.className}
    description={props?.description}
    items={map(props?.imagesAndCatalogs, ({ download, fileUrl }) => download?.downloadUrl && {
      downloadUrl: download?.downloadUrl,
      imageUrl: fileUrl,
      name: download?.name
    })}
    title={props?.title}
    type={'catalogs'}
    url={props?.linkUrl}
  />
);

/**
 * Export `CollectionsSliderModule` component.
 */

export const CollectionsSliderModule = (props: Props) => (
  <SliderSection
    className={props?.className}
    description={props?.description}
    items={props?.collections}
    title={props?.title}
    type={'collections'}
    url={props?.linkUrl}
  />
);

/**
 * Export `ContactsModule` component.
 */

export const ContactsModule = (props: Props) => (
  <ContactsSection
    {...pick(props, ['title'])}
    addresses={map(props?.contacts, (contact, index: number) => ({
      id: index,
      localAddress: contact?.description,
      localName: contact?.name,
      mapUrl: contact?.mapUrl
    }))}
    bgColor={props?.moduleColor}
    buttonLabel={props?.linkLabel}
    buttonUrl={props?.linkUrl}
    className={props?.className}
    imageUrl={props?.media[0]?.fileUrl}
    label={props?.subtitle}
  />
);

/**
 * Export `ContactsFormModule` component.
 */

export const ContactsFormModule = (props: Props) => (
  <ContactFormSection
    description={props?.description}
    imageUrl={props?.media[0]?.fileUrl}
    title={props?.title}
  />
);

/**
 * Export `DownloadsModule` component.
 */

export const DownloadsModule = ({ className, downloads, ...rest }: Props) => (
  <DownloadsSection
    {...pick(rest, ['description', 'title'])}
    className={className}
    items={map(downloads, ({ downloadUrl, name }) => ({
      downloadUrl,
      name,
      type: 'general'
    }))}
  />
);

/**
 * Export `HighlightOneImagesModule` component.
 */

export const HighlightOneImagesModule = (props: Props) => (
  <HighlightOneImageSection
    {...pick(props, ['title'])}
    bgColor={props?.moduleColor}
    buttonLabel={props?.linkLabel}
    buttonUrl={props?.linkUrl}
    className={props?.className}
    hasPageTitle={props?.hasPageTitle}
    imageUrl={props?.media[0]?.fileUrl}
    label={props?.subtitle}
  />
);

/**
 * Export `HighlightTwoImagesModule` component.
 */

export const HighlightTwoImagesModule = (props: Props) => (
  <HighlightTwoImagesSection
    {...pick(props, ['description', 'lead', 'reverse', 'title', 'titleTag'])}
    className={props?.className}
    hasPageTitle={props?.hasPageTitle}
    imageBigUrl={props?.media[0]?.fileUrl}
    imageSmallUrl={props?.media[1]?.fileUrl}
  />
);

/**
 * Export `HighlightThreeImagesModule` component.
 */

export const HighlightThreeImagesModule = (props: Props) => (
  <HighlightThreeImagesSection
    {...pick(props, ['description', 'reverse', 'title'])}
    bottomImageUrl={props?.media[0]?.fileUrl}
    className={props?.className}
    label={props?.subtitle}
    mainImageUrl={props?.media[1]?.fileUrl}
    sideImageUrl={props?.media[2]?.fileUrl}
    url={props?.linkUrl}
  />
);

/**
 * Export `IframeModule` component.
 */

export const IframeModule = (props: Props) => (
  <IframeSection
    className={props?.className}
    url={props?.linkUrl}
  />
);

/**
 * Export `ShowroomsModule` component.
 */

export const ShowroomsModule = ({ className }: Props) => (
  <ShowroomsSection className={className} />
);

/**
 * Export `ImageSliderModule` component.
 */

export const ImageSliderModule = ({ className, images, subtitle }: Props) => (
  <ImagesGallery
    className={className}
    items={map(images, ({ description, image }) => ({
      id: image?.id,
      title: description,
      url: image?.fileUrl
    }))}
    label={subtitle}
  />
);

/**
 * Export `MagazinesSliderModule` component.
 */

export const MagazinesSliderModule = (props: Props) => (
  <SliderSection
    className={props?.className}
    description={props?.description}
    items={props?.magazines}
    title={props?.title}
    type={'magazines'}
    url={props?.linkUrl}
  />
);

/**
 * Export `TextImageModule` component.
 */

export const TextImageModule = (props: Props) => (
  <TextImageSection
    {...pick(props, ['description', 'lead', 'reverse', 'title', 'titleTag'])}
    bgColor={props?.moduleColor}
    buttonLabel={props?.linkLabel}
    buttonUrl={props?.linkUrl}
    className={props?.className}
    hasPageTitle={props?.hasPageTitle}
    imageUrl={props?.media[0]?.fileUrl}
  />
);

/**
 * Export `TextImageSubtitleModule` component.
 */

export const TextImageSubtitleModule = (props: Props) => (
  <TextImageSubtitleSection
    {...pick(props, ['lead', 'reverse', 'title'])}
    bgColor={props?.moduleColor}
    buttonLabel={props?.linkLabel}
    buttonUrl={props?.linkUrl}
    className={props?.className}
    hasPageTitle={props?.hasPageTitle}
    imageUrl={props?.media[0]?.fileUrl}
    label={props?.subtitle}
  />
);

/**
 * Export `TextModule` component.
 */

export const TextModule = ({ className, text }: Props) => (
  <TextSection
    className={className}
    text={text}
  />
);

/**
 * Export `ToolsModule` component.
 */

export const ToolsModule = ({ blocks, className, hasPageTitle, ...rest }: Props) => (
  <ToolsSection
    {...pick(rest, ['description', 'title'])}
    className={className}
    hasPageTitle={hasPageTitle}
    items={map(blocks, ({ description, image, linkLabel, linkUrl, title }) => ({
      buttonLabel: linkLabel,
      buttonUrl: linkUrl,
      description,
      icon: image?.fileUrl,
      iconType: 'image',
      title
    }))}
  />
);
