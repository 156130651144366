
/**
 * Module dependencies.
 */

import { media } from 'src/styles/utils';
import Container from 'src/components/core/layout/container';
import Iframe from 'src/components/iframe';
import ParallaxFadeInUp from 'src/components/core/animations/parallax-fade-in-up';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  url: string
}

/**
 * `StyledIframe` styled component.
 */

const StyledIframe = styled(Iframe)`
  padding-top: 0 !important;

  ${media.max('md')`
    padding-left: 0;
    padding-right: 0;
  `}
`;

/**
 * `IframeSection` component.
 */

const IframeSection = (props: Props): ReactElement | null => {
  const { className, url } = props;

  return (
    <section className={className}>
      <Container>
        <ParallaxFadeInUp parallaxOptions={{ speed: 1 }}>
          <StyledIframe url={url} />
        </ParallaxFadeInUp>
      </Container>
    </section>
  );
};

/**
 * Export `IframeSection` component.
 */

export default IframeSection;
