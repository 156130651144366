
/**
 * Module dependencies.
 */

import { kioskRoutes } from 'src/core/routes';
import { useRouter } from 'next/router';
import React, {
  ElementType,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef
} from 'react';

import Rellax from 'rellax';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  as?: ElementType,
  children: ReactNode,
  className?: string,
  percentage?: number,
  speed?: number
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  height: auto;
`;

/**
 * `Parallax` component.
 */

const Parallax = (props: Props): ReactElement => {
  const { children, percentage, speed, ...rest } = props;
  const router = useRouter();
  const isKiosk = useMemo(() => {
    return router.asPath.includes(kioskRoutes.home);
  }, [router.asPath]);

  const ref = useRef(null);
  const options = useMemo(() => {
    return isKiosk ? {} : {
      'data-rellax-percentage': percentage,
      'data-rellax-speed': speed
    };
  }, [isKiosk, percentage, speed]);

  useEffect(() => {
    if (ref && ref.current && !isKiosk) {
      const instance = new Rellax(ref.current, {
        center: true
      });

      return () => {
        instance.destroy();
      };
    }
  }, [isKiosk]);

  return (
    <Wrapper
      {...rest}
      {...!isKiosk && { ref, ...options }}
    >
      {children}
    </Wrapper>
  );
};

/**
 * Export `Parallax` component.
 */

export default Parallax;
