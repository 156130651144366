
/**
 * Module dependencies.
 */

import { units } from 'src/styles/utils';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

/**
 * `Prop` type.
 */

type Props = {
  className?: string,
  url: string
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  height: 100vh;
  max-width: 100%;
  padding: ${units(20)} 37px;
  width: 100vw;
`;

/**
 * `Iframe` styled component.
 */

const Iframe = styled.iframe`
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

/**
 * `IFrame` component.
 */

const IFrame = ({ className, url }: Props): ReactElement => (
  <Wrapper className={className}>
    <Iframe
      frameBorder={0}
      height={'100%'}
      src={url}
      width={'100%'}
    />
  </Wrapper>
);

/**
 * Export `IFrame` component.
 */

export default IFrame;
